/* Brand Header css */
.brand-page .category-list {
  background: #fafaff;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.brand-page .category-list-inner {
  max-width: 1200px;
  margin: 0 auto;
}
.brand-page .brand-grid-item {
  box-shadow: unset;
}
.brand-page .brand-logo {
  padding-right: 20px;
}
.brand-page .brand-content {
  width: 60%;
}
.leave-review a {
  display: flex;
  align-items: center;
  text-decoration: unset;
}
.leave-review p {
  margin: 0;
  padding-right: 12px;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.brand-banner .review-rating svg {
  width: 30px;
  height: 30px;
}
.brand-page .review-rating {
  padding: 5px 0px 10px;
}
.brand-banner .review-rating p {
  font-size: 20px;
  margin-left: 5px;
}
.brand-page .write-review-rating span {
  padding-right: 2px;
}
.verified-brand {
  display: flex;
  align-items: center;
}
.verified-brand p {
  margin: 0;
  margin-right: 5px;
}
.leave-review {
  min-width: max-content;
}
/* {% comment %} Rating Container Css {% endcomment %} */
.rating-container {
  width: 100%;
}
.rating-container-inner {
  background: #fafaff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  padding: 24px 55px;
}
.rating-container-top {
  display: flex;
  padding-bottom: 10px;
  flex-direction: column;
}
.review-avg-rating span {
  position: relative;
  top: 1px;
}
.review-avg-rating p {
  margin: 0;
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.review-avg-rating svg {
  width: 22px;
  height: 22px;
  margin: 0px 5px;
}
.average-rating-and-star {
  display: flex;
  align-items: center;
}
.average-rating {
  font-family: sofia-pro;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
  margin-right: 10px;
}
body .star {
  display: flex;
}
.total-review-and-label {
  display: flex;
  align-items: center;
}
.review-count {
  padding-right: 5px;
  color: #5c7dd3;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.total-review-and-label label {
  color: #5c7dd3;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.rating-container-bottom > div {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.rating-container-bottom > div > label {
  min-width: 80px;
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.rating-container-bottom > div p {
  margin: 0;
  margin-left: 10px;
  min-width: 38px;
}
.rating-container-bottom > div > span {
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 10px;
  background: #dfe4ef;
  height: 16px;
}
.rating-container-bottom > div > span .progress-width {
  content: "";
  position: absolute;
  height: 100%;
  transition: width 1s ease-in-out 0s;
}
.wholescale-review-widget .star-count {
  margin: 0;
  line-height: 16px;
  font-size: 12px;
  padding-left: 10px;
}
.brand-main-inner {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.brand-details {
  flex-grow: 1;
  max-width: 70%;
  padding-right: 20px;
}
.rating-star-5 .progress-width {
  border-radius: 10px;
  background: #0bcfab;
}
.rating-star-4 .progress-width {
  border-radius: 10px;
  background: #33f6d3;
}
.rating-star-3 .progress-width {
  border-radius: 10px;
  background: #fece52;
}
.rating-star-2 .progress-width {
  border-radius: 10px;
  background: #ff7448;
}
.rating-star-1 .progress-width {
  border-radius: 10px;
  background: #f6336d;
}
.rating-grid-item.active {
  background-color: #205dd4;
}
.rating-grid-item.active span {
  color: #fff;
}
.product-formcontrol {
  max-width: 100%;
}
/* Mobile media query */
@media (max-width: 899px) {
  .rating-container {
    margin: 0;
    max-width: 100%;
  }
  .reviews-container {
    margin-top: 20px;
  }
}

/* Brand List Css */
.brand-list-inner {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
}
.brand-list-inner .review-item {
  width: calc(50% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
}
.rating-container-bottom {
  border-bottom: 1px solid #dedede;
  margin-bottom: 20px;
}
.sort-by-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sort-by-inner > label {
  font-weight: 900;
  margin-right: 10px;
}
.sort-by-container {
  position: relative;
}
.selected-value {
  margin: 0;
  background-color: transparent;
  border: 1px solid #205cd4;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  color: #205cd4;
  font-weight: 600;
  cursor: pointer;
  min-width: 125px;
  display: flex;
  justify-content: space-between;
}
.selected-value svg {
  fill: #205cd4;
}
.sort-by-options {
  position: absolute;
  top: 120%;
  background-color: #fff;
  box-shadow: 0 0 8px 0 #0000004d;
  width: 360px;
  padding: 20px;
  border-radius: 16px;
  display: none;
}
.sort-by-options.active {
  display: block;
}
.sort-by-formcontrol .MuiFormHelperText-root {
  padding-left: 33px;
  margin: 0;
  line-height: inherit;
  font-size: 14px;
  color: #696a6a;
  letter-spacing: 0px;
}
.brand-page a[href="#"] {
  pointer-events: none;
}
.shop-now  {
  width: 205px;
}
.shop-now > a {
  border: 1px solid #011566;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  color: #011566;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
/* Mobile media query css */
@media (max-width: 899px) {
  .brand-page .brand-grid-item {
    flex-wrap: wrap;
    height: auto;
    padding-top: 30px;
  }
  .brand-page .review-rating {
    padding: 5px 0px 0px;
  }
  .brand-banner .review-rating svg {
    width: 20px;
    height: 20px;
  }
  .brand-banner .review-rating p:first-of-type {
    font-size: 14px;
    margin-left: 10px;
  }
  .brand-banner .brand-content .review-rating p:last-child {
    flex-grow: 1;
  }
  .leave-review {
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .leave-review p {
    font-size: 15px;
  }
  .brand-main-inner {
    padding: 15px;
    flex-direction: column-reverse;
  }
  .brand-list-inner {
    margin-right: 0;
  }
  .brand-list-inner .review-item {
    width: 100%;
    margin-right: 0;
  }
  body .brand-page .review-item > div {
    padding: 20px;
  }
  .brand-details {
    max-width: 100%;
  }
  body .brand-page .rating-container-inner {
    padding: 15px;
  }
  .review-avg-rating p {
    font-size: 16px;
  }
  .review-avg-rating svg {
    width: 14px;
    height: 14px;
  }
  .total-review-and-label label,
  .review-count,
  .rating-container-bottom > div p {
    font-size: 12px;
  }
  .rating-container-bottom > div > label {
    font-size: 12px;
    min-width: 45px;
    line-height: initial;
  }
  .rating-container-bottom > div > span {
    height: 9px;
  }
  .brand-page .brand-details {
    max-width: 100%;
    padding: 0;
  }
  .sort-by-options {
    left: 50%;
    max-width: 90%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 9;
    padding: 10px 20px;
  }
  .sort-by-container {
    position: unset;
  }
  .sort-by {
    position: relative;
  }
  .shop-now {
    flex: 0 0 100%;
  }
  .shop-now > a {
    max-width: 205px;
    margin-bottom: 10px;
  }
}
.brand-logo img {
  border-radius: unset;
}
.back-button {
  max-width: 1200px;
  margin: 0 auto;
}
.back-button button {
  margin: 0;
  margin-left: 20px;
}
body .review-submission {
  background-color: #0165ff;
}
.brand-content > a {
  text-decoration: unset;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination > div {
  cursor: pointer;
}
.pagination .prev {
  margin-right: 20px;
}
.pagination svg {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #001566;
  color: #001566;
}
.pagination .disabled {
  pointer-events: none;
  opacity: 0.3;
}
@media (min-width: 900px) {
  .brand-page .brand-grid-item {
    justify-content: space-between;
  }
}
