.category-main-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  padding-top: 80px;
}

/* Filters.js css */
.filter {
  width: 340px;
}
.filter-inner {
  padding-right: 20px;
}
.card {
  border-radius: 2px;
  background: #fafaff;
  box-shadow: 1px 1px 4px -1px rgba(37, 47, 66, 0.25);
  padding: 25px;
  margin-bottom: 20px;
}
.rating {
  margin-bottom: 30px;
}
.rating h3 {
  margin: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.rating-grid {
  display: flex;
}
.brand-page .rating-grid {
  margin-right: -10px;
}
.rating-grid .rating-grid-item {
  background-color: #ebecf1;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.rating-grid .rating-grid-item span {
  color: #205dd4;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
body .review-status-label {
  color: #000 !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.review-status-radio label {
  color: #001666;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.filter-card-2 label {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
  display: flex;
}
.review-status-radio .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0px 10px;
}
.review-status-radio > div {
  display: flex;
  align-items: center;
}
.review-status-radio label {
  min-width: 60px;
}
.review-status-radio .Mui-checked {
  color: #0ba9a4;
}
.filter-category-formcontrol {
  border-radius: 20px;
  background-color: #ebecf1;
}
.filter-category-formcontrol .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #6d6f79;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: "Poppins";
}
.star-2 path {
  fill: #ff7448;
}
.star-3 path {
  fill: #fece52;
}
.star-4 path {
  fill: #80DF50;
}
.star-5 path {
  fill: #08a9a4;
}
.social-icons {
  padding-top: 15px;
}
/* Category List Css */
body .sortby-formcontrol {
  min-width: 200px;
}
.sortby-formcontrol fieldset {
  border-radius: 20px;
}
.category-list {
  flex-grow: 1;
}
.category-list-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.sortby-formcontrol > div {
  background: #ebecf1;
  border-radius: 20px;
}
.sortby-formcontrol .MuiSelect-outlined {
  color: #6d6f79;
  text-align: left;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.pagination {
  margin: 0;
}
.brand-grid-item {
  display: flex;
  position: relative;
  border-radius: 12px;
  background: #fafaff;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 15px;
  margin-bottom: 20px;
  height: 140px;
  align-items: center;
  text-decoration: unset;
  color: inherit;
}
.brand-logo {
  width: max-content;
  padding-right: 10px;
}
.brand-logo img {
  max-width: 120px;
  width: 100%;
  border-radius: 50%;
}
.brand-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.brand-content h2 {
  margin: 0;
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.brand-content .review-rating p:first-child {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.brand-content .review-rating p:last-child {
  color: #0A36D5;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 20px;
}
.brand-label {
  position: absolute;
  right: 20px;
  top: 20px;
  width: max-content;
}
.brand-label span {
  border-radius: 4px;
  background: #9747FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 0px 10px;
}
.brand-uper {
  text-decoration: unset;
}
.social-icons a {
  color: #000;
  text-decoration: unset;
}
/* Mobile media query  */
@media (max-width: 767px){
  .filter {
    display: block;
    width: 100%;
  }
  .filter-inner {
    padding: 0;
  }
  .brand-grid-item {
    padding: 10px;
    max-width: 100%;
    height: 170px;
    display: flex;
    align-items: end;
  }
  .brand-logo {
    width: 40%;
    max-width: max-content;
    padding-right: 20px;
  }
  .brand-logo img {
    width: 100%;
  }
  .brand-content {
    width: 60%;
  }
  .brand-content .review-rating {
    flex-wrap: wrap;
    max-width: 200px;
  }
  .brand-content .review-rating p:last-child {
    margin: 0;
  }
  .brand-label {
    position: absolute;
    top: 10px;
    right: 10px;
    width: max-content;
  }
  .brand-label span {
    padding: 0px 10px;
  }
  .brand-label span {
    font-size: 12px;
  }
  .brand-content .review-rating p:last-child {
    font-size: 14px;
    min-width: 100%;
  }
  .brand-content h2 {
    font-size: 18px;
  }
  .category-main-inner {
    flex-direction: column;
  }
  .pagination {
    font-size: 12px;
  }
  .brand-grid-item {
    align-items: center;
  }
}