.footer {
  background-color: #001666;
}
.footer-inner {
  max-width: 1050px;
    margin: 0 auto;
}
.footer-top {
  padding-top: 64px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.footer-top h3 {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 9px;
}
.footer-top ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-top ul li {
  padding-bottom: 9px;
}
.footer-top ul li {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-top ul li a {
  text-decoration: unset;
  color: #FFF;
  font-family: Poppins;
}
.keep-in-touch-form {
  position: relative;
}
.keep-in-touch-form input {
  color: #6D6F79;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 40px;
  border-radius: 20px;
  background: #EBECF1;
  padding-left: 20px;
  min-width: 328px;
  border: unset;
}
.keep-in-touch-form button {
  width: 76px;
  height: 30px;
  border: 1px solid #06f;
  border-radius: 30px;
  background: #06F;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: inherit;
  cursor: pointer;
}
.footer-bottom-inner {
  padding-bottom: 56px;
}
.honestar-footer-logo {
  margin-bottom: 16px;
}
.honestar-footer-logo svg {
  width: 50px;
}
.footer-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.footer-links li {
  color: #ECF6FF;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 16px;
}
.footer-links li a {
  color: #fff;
  text-decoration: unset;
}

/* Media query mobile start */
@media (max-width: 767px) {
  .footer-top {
    flex-direction: column-reverse;
    padding: 20px;
  }
  .column-1, .column-2 {
    display: none;
  }
  .column-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .column-4 {
    margin-bottom: 30px;
  }
  .keep-in-touch-form {
    max-width: 400px;
  }
  .footer-top h3 {
    margin: 0;
  }
  .footer-top ul li {
    padding: 0;
  }
  .footer-top .column-4 h3 {
    margin-bottom: 15px;
  }
  .keep-in-touch-form input {
    padding: 0;
    min-width: auto;
    width: 100%;
    max-width: 400px;
  }
  .keep-in-touch-form input::placeholder {
    padding-left: 10px;
  }
  .footer-bottom-inner {
    padding: 20px;
    display: flex;
    align-items: center;
  }
  .honestar-footer-logo {
    margin: 0;
    margin-right: 5px;
  }
  .footer-links li {
    padding-right: 8px;
  }
}