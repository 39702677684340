/* Category Banner */
.category-image {
  width: 100%;
}
.category-image img {
  width: 100%;
}
.category-banner-inner {
  position: relative;
}
body .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
body .text-content-inner {
  max-width: 400px;
  margin-bottom: 20px;
}
body .cat-heading {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 5px;
  font-family: "Poppins";
}
body .cat-subheading {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-family: "Poppins";
}
body .cat-subheading span {
  color: #33f6d3;
  font-family: "Poppins";
}
body .search-cat-container {
  max-width: 650px;
  border-radius: 20px;
  background: #ebecf1;
  height: 40px;
  min-width: 650px;
  display: none;
}
body .search-cat-container > div {
  flex-grow: 1;
  height: inherit;
}
body .category-input input {
  padding-left: 25px;
}
body .category-input fieldset {
  display: none;
}
body .search-button {
  max-width: max-content;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 10px;
  border-left: 1px solid #a3add4;
}
body .search-button button {
  max-width: max-content;
  padding: 6px 16px;
  border-radius: 30px;
  background: #06f;
  color: #fff;
  height: 30px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  font-family: "Poppins";
  border: 1px solid #06f;
}
body .search-button button:hover {
  background-color: #ebecf2;
  color: #06f;
}
.keep-in-touch-form button:hover {
  background-color: #ebecf2;
  color: #06f;
}
body .category-formcontrol {
  min-width: 100%;
  height: inherit;
}
body .category-formcontrol > div {
  height: inherit;
  color: #6d6f79;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}
body .category-input input {
  height: inherit;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  font-family: "Poppins";
}
body .category-formcontrol fieldset {
  display: none;
}
body .category-dropdown {
  border-left: 1px solid #a3add4;
  width: 200px;
  max-width: 200px;
  font-family: "Poppins";
}
.category-input > div {
  width: 100%;
}
.honestar-logo svg {
  width: 150px;
}
.honestar-logo .omni-text {
  fill: #fff;
  stroke: #fff;
}
/* Mobile Media Query starts */
@media (max-width: 767px) {
  .honestar-logo {
    display: flex;
  }
  body .category-banner-inner {
    height: auto;
    background-repeat: no-repeat;
    background-image: url("../../Assets/category-banner-mobile.png");
  }
  body .search-cat-container {
    flex-direction: column;
    height: auto;
    max-width: 100%;
    min-width: 100%;
    background: unset;
  }
  body .search-cat-container > div {
    height: 40px;
    border-radius: 20px;
    margin-bottom: 20px;
  }
  .category-input {
    background: #ebecf1;
  }
  body .category-dropdown {
    background: #ebecf1;
    width: 100%;
    max-width: 100%;
    border: unset;
  }
  body .search-button {
    max-width: 100%;
    padding: 0;
    border: unset;
  }
  body .search-button button {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

/* Top Categories */
.category-top h3 {
  color: #001666;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.top-categories-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.top-categories-inner h1 {
  color: #001666;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
}
.category-bottom-inner {
  display: flex;
  flex-wrap: wrap;
}
.category-item {
  width: calc(25% - 116px);
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 48px;
  margin-right: 20px;
  margin-bottom: 35px;
  gap: 2px;
  border-radius: 47px;
  background: #f5f6ff;
  box-shadow: 1px 1px 4px -1px rgba(37, 47, 66, 0.25);
  text-decoration: unset;
}
.category-item .title h3 {
  color: #205dd4;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}
.category-item .stats {
  display: flex;
  align-items: center;
}
.category-item .brands {
  color: #5c7dd3;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}
.category-item .reviews {
  color: #5c7dd3;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}
.category-item .reviews svg {
  width: 20px;
  height: 20px;
}
/* Mobile Media query start */
@media (max-width: 767px) {
  .category-bottom-inner {
    gap: 10px;
  }
  .category-item {
    margin: 0;
    width: calc(50% - 40px);
    padding: 0;
    flex-grow: 1;
    margin-bottom: 10px;
  }
  .category-item .icon {
    padding-top: 4px;
  }
  .category-item .title h3 {
    font-size: 11px;
  }
  .category-item .brands {
    font-size: 8px;
  }
  .category-item .reviews {
    font-size: 8px;
  }
}
/* latest-reviews */
.latest-reviews {
  background-color: #e9efff;
}
.latest-reviews-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.reviews-top h1 {
  color: #001666;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 0;
}
body .review-item-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 2px;
  background: #fbfbff;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.15);
  min-height: 335px;
  padding: 15px;
}
.swiper-wrapper {
  padding: 20px 0px 40px;
}
.review-item-top {
  display: flex;
}
.product-image {
  margin-right: 10px;
}
.product-image img {
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #769cff;
  padding: 10px;
}
h3.product-title {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0;
}
.review-rating {
  display: flex;
  align-items: center;
}
.review-title {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  margin-top: 10px;
}
.review-description {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-height: 48px;
  color: #000;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.reviewed-label {
  overflow: hidden;
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.review-rating p {
  margin: 0;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: 5px;
}
.review-date {
  color: #0a36d5;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.reviewed-by {
  display: flex;
  flex-direction: column;
}
.review-item-center {
  margin-bottom: 10px;
}
.review-rating .MuiRating-iconFilled,
.review-rating .MuiRating-iconEmpty {
  color: #08a9a4;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: #7a7a7a;
}
.swiper-pagination-bullet-active {
  background: #0a36d5 !important;
}

/* Mobile media query start */
@media (max-width: 767px) {
  body .review-item {
    max-width: 100%;
    padding: 0;
  }
  body .review-item > div {
    padding: 15px;
  }
  .swiper-wrapper {
    margin-left: -5px;
  }
}

/* Promotional Banner */
body .sub-banner-subtext {
  line-height: 1;
  font-size: 13px;
  font-family: "Poppins";
  margin-top: 5px;
}
/* Mobile media query start */
@media (max-width: 767px) {
  .promotional-banner .category-banner-inner {
    height: 408px;
    background-repeat: no-repeat;
    background-image: url("../../Assets/promotional-banner-mobile.png");
  }
  body .text-content {
    justify-content: flex-start;
    padding-top: 45px;
  }
  body .sub-banner-subtext {
    line-height: 16px;
    font-weight: 400;
  }
  body .cat-subheading {
    margin-bottom: 10px;
  }
}

/* Tablet Media Query */
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .category-item {
    width: calc(33.33% - 80px);
    padding: 7px 30px;
  }
  .category-bottom-inner {
    margin-right: -20px;
  }
}

body .top-categories .skeleton-item {
  transform: unset;
  width: calc(25% - 116px);
  min-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 48px;
  margin-right: 20px;
  margin-bottom: 35px;
  gap: 2px;
  border-radius: 47px;
  box-shadow: 1px 1px 4px -1px rgba(37, 47, 66, 0.25);
  text-decoration: unset;
}
body .skeleton-loading {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
body .latest-reviews .skeleton-item {
  flex-grow: 1;
  width: calc(33.33% - 30px);
  margin-right: 30px;
  height: 300px;
  transform: unset;
}
.category-item svg {
  color: #205DD4;
}
.category-item .icon {
  display: flex;
}
.honestar-logo a{
  display: flex;
}
.footwear-icon {
  width: 24px;
    height: 24px;
}
.footwear-icon path {
  fill: #205dd4;
  stroke: #205dd4;
}
.category-item:hover {
  background-color: #205DD4;
}
.category-item:hover .title h3, .category-item:hover .brands,
.category-item:hover .reviews, .category-item:hover .reviews svg, .category-item:hover svg, .category-item:hover .reviews path  {
  color: #fff;
  fill: #fff;
}
.category-item:hover svg.footwear-icon path {
  fill: #fff;
  stroke: #fff;
}