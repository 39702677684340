.write-review-page .category-list {
  background: #fafaff;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.write-review-page .category-list-inner {
  max-width: 1200px;
  margin: 0 auto;
}
.write-review-page .brand-grid-item {
  box-shadow: unset;
  align-items: unset;
}
.write-review-page .brand-logo {
  padding-right: 20px;
}
.write-review-page .brand-content {
  width: 60%;
}
.write-review-page .review-rating {
  padding: 5px 0px 10px;
}

/* Write review css */
.review-form-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.review-form-content {
  max-width: 740px;
  padding: 55px;
  border-radius: 6px;
  background: #fbfbfc;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.review-form-content > div {
  padding-bottom: 30px;
}
.review-form-content > div > label,
.review-form-content > div > h2 {
  margin: 0;
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 15px;
  display: flex;
}
.select-product > div,
.write-title > div {
  width: 100%;
}
.write-review-rating svg {
  width: 36px;
  height: 36px;
}
.pre-comments ul {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 20px;
}
.pre-comments ul li {
  border-radius: 30px;
  border: 1px solid #0a36d5;
  padding: 6px 16px;
  color: #001666;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 5px;
  margin-bottom: 12px;
}
.write-description-input > div {
  width: 100%;
}
.write-description-input textarea {
  height: 230px;
  color: #7f8498;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.react-file-reader {
  border-radius: 12px;
  border: 1px solid #0a36d5;
  width: max-content;
  padding: 8px 12px;
}
.react-file-reader-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.react-file-reader-button label {
  color: #001666;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding-right: 5px;
  cursor: pointer;
}
.submit-review span {
  border-radius: 30px;
  background: #06f;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.write-review-rating > span > label {
  padding-right: 5px;
}
.write-title fieldset,
.select-product fieldset {
  border-radius: 20px;
  border: unset;
}
.write-title input,
.select-product .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  background: #ebecf1;
  border-radius: 20px;
  border: unset;
}
.select-product-dropdown > div > div {
  background: #ebecf1;
  border-radius: 20px;
  border: unset;
}
.list-review li {
  cursor: pointer;
}
/* Write a review Media query */
@media (max-width: 899px) {
  .review-form-content > div > label,
  .review-form-content > div > h2 {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .write-review-rating svg {
    width: 30px;
    height: 30px;
  }
  .review-form-inner {
    padding: 15px;
  }
  .review-form-content {
    padding: 15px 25px;
  }
  .select-product .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  .write-title .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 14px;
  }
  .write-description-input textarea {
    font-size: 13px;
  }
  .react-file-reader-button label {
    font-size: 16px;
  }
  .react-file-reader-button svg {
    width: 14px;
    height: 14px;
  }
  .submit-review span {
    font-size: 14px;
    padding: 10px 25px;
  }
}
