
.header {
  background-color: #001666;
}
.header-inner {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 20px;
  height: 56px;
}
.honestar-contact button {
  border-radius: 30px;
  background: #06F;
  color: #fff;
  font-size: 12px;
  padding: 6px 16px;
  border: 1px solid #06F;
}
.honestar-contact button:hover {
  border: 1px solid #fff;
  background-color: #fff;
  color: #06F;
}
.boost-text .st2 {
  fill: #67EFE2;
}
path.st2.rocket-base {
  fill: #67EFE2;
}