
body {
  font-family: "Poppins";
}
.privacy-policy, .terms-and-condition-inner {
  max-width: 1165px;
  margin: 0 auto;
  padding: 0px 20px;
  min-height: 350px;
}
.privacy-policy ul{
  list-style: none;
}
.privacy-policy h1, .terms-and-condition h1 {
  color: #001566;
}
.review-media-item {
  margin-right: 10px;
}
.review-media-item img {
  width: 100%;
  max-width: 41px;
  cursor: pointer;
}
.review-media-inner {
  display: flex;
}
.review-item-bottom {
  display: flex;
    justify-content: space-between;
}
.view-reply span {
  background: #ECF2FF;
  width: 92px;
  height: 29px;
  border-radius: 20px;
  display: flex;
  color: #205DD4;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.footer-top ul li a:hover, .footer-links li a:hover {
  text-decoration: underline;
  color: #ffffffdb;
}
.image-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  min-height: 300px;
}
.image-content [data-testid="CloseIcon"] {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #fff;
  z-index: 99;
  cursor: pointer;
  color: #000;
}
.reply-content .replied-by{
  font-family: Poppins;
  font-size: 12.462px;
  font-style: normal;
  font-weight: 400;
  line-height: 43.615px;
  margin: 0;
}
.reply-content .replied-by span{
  color: #000;
  font-family: Poppins;
  font-size: 12.462px;
  font-style: italic;
  font-weight: 600;
  line-height: 43.615px;
}
.reply-content .created-at {
  color: #6D6D6D;
    font-family: Poppins;
    font-size: 12.462px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.reply-content .popup-footer {
  background: #001666;
}
.reply-content .popup-footer button {
  border-radius: 46.731px;
  background: #06F;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15.577px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 5px 20px;
}
.image-content img {
  width: 100%;
}
.tc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
@media (max-width: 767px) {
  .image-content {
    min-width: 200px;
    min-height: 200px;
  }
}